<template>
    <AtualizarUsuario />
</template>

<script>
import AtualizarUsuario from '@/components/Usuario/AtualizarUsuario'
export default {
  components: {AtualizarUsuario},
  data(){
    return{}
  }
}
</script>

<style>

</style>