<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Atualizar usuário id: #{{idusuario}}</h3>
        <p>Segue abaixo o formuário de ataulização. Preencha com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.push('/lista/usuario')">voltar</button>
    </div>
    <form class="formulario" autocomplete='off'>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @click="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            :error-messages="erros.nome"
            label="Nome Completo"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @click="(e) => selecionarcampo(e)"
            v-model="email"
            name="email"
            :error-messages="erros.email"
            label="Email do usuário"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @click="(e) => selecionarcampo(e)"
            v-model="fone"
            name="fone"
            :error-messages="erros.fone"
            v-mask="'(##) # ####-####'"
            label="Telefone"
            type="tel"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-switch
            v-model="status"
            value="status"
            inset
            :label="`Usuário ${status ? 'ativo' : 'inativo'}`"
          ></v-switch>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            atualizar usuário
          </v-btn>
        </v-col>
      </v-row>    
    </form>
  </v-container>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import UsuarioServico from '@/services/usuario.service.js'
export default {
  data(){
    return {
      nome: null,
      email: null,
      fone: null,
      status: null,
      idusuario: null,
      usuario: {},
      reqloading: false,
      erros: {
        nome: '',
        email: '',
        fone: '',
      }
    }
  },
  methods: {
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        const dadosusuario = {
          id: this.idusuario,
          nome: this.nome,
          email: this.email,
          fone: this.fone,//.replace('(', '').replace(')', '').replace(' ', '').replace(' ', '').replace('-', ''),
          status: this.status,
        }
        await UsuarioServico.atualizar(dadosusuario)
            .then(res => {
              if(res.status === 200){
                alertar('success', '=)', 'Usuário atualizado com sucesso')
              }
            })
            .catch(() => {
              alertar('warning', '=/', 'Falha na atualização do usuário')
            })
        this.reqloading = false
      }
    },
    async requisitarUsuario(){
      this.usuario = await UsuarioServico.buscar({id: this.idusuario}).then(res => res.data[0])
    },
    validacao(){
      let validado = true
      if(!this.fone || this.fone.trim() === ''){
          validado = false
          this.erros.fone = 'O campo telefone é obrigatório'
      }
      if(!this.nome || this.nome.trim() === ''){
        validado = false
        this.erros.nome = 'O campo nome é obrigatório.'
      }
      if(!this.email || this.email.trim() === ''){
        validado = false
        this.erros.email = 'O campo e-mail é obrigatório.'
      }
      if(!this.email.includes('@')){
        validado = false
        this.erros.email = 'Insira um e-mail válido.'
      }
      return validado
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    }
  },
  async mounted(){
    const { id } = this.$route.params
    this.idusuario = id
    await this.requisitarUsuario()
    this.nome = this.usuario.nome
    this.email = this.usuario.email
    this.fone = this.usuario.fone
    this.status = this.usuario.ativo
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

//css indesejad
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: unset;
    min-width: unset;
    padding: unset;
  }
</style>